import ErpBadge from '@client/ui/orgunit/ErpBadge/index';
import { ErpUsage } from '@mmw/services-core-manu-orgunit/types';
import StoreLocatorContent, {
  CityFormField,
  MeasureUnitFormField,
  RadiusSelectFormField,
  ZipcodeFormField,
} from '@store-locator/view';
import Page from '@store-locator/view/components/page';
import { NameComponent } from '@store-locator/view/components/StoreName';
import { CustomCompStoreProp } from '@store-locator/view/context';
import { css } from '@ui-system/css';
import { useIsMediaQueryUpToMD } from '@ui-system/media-query';
import { useColors } from '@ui-system/theme/colors';
import UI from '@ui-system/ui';
import get from 'lodash/get';
import { MaterialDesignContent } from 'notistack';
import React from 'react';
import styled from 'styled-components';

type CustomCompExtendedProp = CustomCompStoreProp & {
  erpusage?: ErpUsage;
};
const CustomMapMark: React.FC<CustomCompStoreProp> = ({
  ...props
}: CustomCompStoreProp) => (
  <UI.Icon name="recLocator" color="primary" size={50} {...props} />
);

const CustomErpBadge: React.FC<CustomCompExtendedProp> = ({ erpusage }) => (
  <UI.Container m="0, 2, 0, 0">
    <ErpBadge erpusage={erpusage} width={80} vertical />
  </UI.Container>
);

const CustomCardTitle: React.FC<
  CustomCompStoreProp & { limitWidth: boolean }
> = ({ store, limitWidth = false }) => (
  <UI.Container
    style={css`
      ${limitWidth ? 'max-width: 260px' : ''};
    `}
    align="center"
    gap={2}
  >
    <CustomErpBadge erpusage={store?.erpusage as ErpUsage} />
    <NameComponent store={store} />
  </UI.Container>
);

const CustomStoreName: React.FC<CustomCompStoreProp> = ({
  store,
}: CustomCompStoreProp) => {
  const storeName = get(store, 'address.company', '');
  const displayName = get(store, 'displayname', '');
  return (
    <UI.Typography modifiers="bold, capitalize">
      {storeName || displayName}
    </UI.Typography>
  );
};

const CUSTOM_MAP_CONFIGS = {
  CustomMapMark,
  CustomErpBadge,
  CustomCardTitle,
  disableClickOutsideTooltipBehavior: true,
  CustomStoreName,
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => {
  const colors = useColors();
  return {
    '&.notistack-MuiContent-success': {
      backgroundColor: colors.primary.main,
    },
  };
});

const CUSTOM_NOTIFICATIONS_PROVIDER_PROPS = {
  Components: {
    success: StyledMaterialDesignContent,
  },
};

const STYLE = css`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
`;

const MainPage: React.FC = () => {
  const isMobile = useIsMediaQueryUpToMD();
  return (
    <Page height={0}>
      <Page.Content>
        <StoreLocatorContent
          style={STYLE}
          customConfigs={CUSTOM_MAP_CONFIGS}
          notificationsProviderProps={CUSTOM_NOTIFICATIONS_PROVIDER_PROPS}
        >
          <UI.Container direction="column" gap={2} p="3">
            <CityFormField />
            <UI.Container gap={3} responsive="upToMd.direction=column">
              <ZipcodeFormField
                style={css`
                  width: ${isMobile ? '100%' : '54%'};
                `}
              />
              <UI.Container direction="column">
                <RadiusSelectFormField
                  style={css`
                    width: ${isMobile ? '100%' : '205px'};
                  `}
                />
                <MeasureUnitFormField />
              </UI.Container>
            </UI.Container>
          </UI.Container>
        </StoreLocatorContent>
      </Page.Content>
    </Page>
  );
};

export default MainPage;
